// @flow
import type { RawSettingsType, SettingsType } from 'types/Settings';
import { nullSettings, nullNextMatch, nullCardMenuElement } from 'types/Settings';
import { mergeIntoDefault } from 'utils/objectUtils';
import { nullStructure, type StructureType } from 'types/Result';

export function convertSettingsFromApi(rawSettings: RawSettingsType): SettingsType {
  if (!rawSettings) {
    return nullSettings;
  }

  const {
    adresse,
    telephone,
    ligue,
    title,
    description,
    meta_title_home,
    meta_description_home,
    url,
    logo,
    saison_en_cours,
    liste_cards,
    titre_liste_cards,
    contacts,
    favicons,
    liens_rs,
    newsletter,
    cookies,
    top_news,
    actualite_une,
    partenaires,
    partenaires_page,
    partenaires_une_titre,
    partenaires_une_description,
    partenaires_une_cta,
    banniere,
    rejoignez_nous_background,
    club_id,
    structure_parente,
    rejoignez_nous,
    actualites_une_titre,
    actualites_une_description,
    equipes_une,
    equipes_une_titre,
    equipes_une_description,
    equipes_une_cta,
    equipes_une_lien,
    competitions_equipes: competitionsEquipes,
    prochaine_rencontre: prochaineRencontre,
    prochaines_rencontres,
    prochaine_rencontre_lien,
    structure,
    mode_travaux,
    user_id,
    home_bloc_boutique,
    liste_blocs_un,
    liste_blocs_deux,
    titre_liste_blocs_un,
    titre_liste_blocs_deux,
    placeholder,
    actualite_ffr_vers_ecosysteme,
    cgus,
    cgu,
    google_verification,
    ville,
    acronyme,
    message_alerte
  } = rawSettings;

  let prochainesRencontres = [];
  if (prochaines_rencontres && prochaines_rencontres.length > 0) {
    prochainesRencontres = prochaines_rencontres.map(prochaineRencontre => {
      const { id, Competition, CompetitionEquipeLocale, CompetitionEquipeVisiteuse, Etat, Journee, dateOfficielle, dateEffective } =
        prochaineRencontre || {};
      if (CompetitionEquipeVisiteuse && CompetitionEquipeLocale) {
        const localStructure: StructureType = mergeIntoDefault(
          nullStructure,
          CompetitionEquipeLocale.Structure
            ? {
                ...CompetitionEquipeLocale.Structure,
                isRegroupement: false,
                nom: CompetitionEquipeLocale.nom.replace(/\(.*\)/g, '')
              }
            : {
                ...CompetitionEquipeLocale.Regroupement,
                isRegroupement: true,
                nom: CompetitionEquipeLocale.nom.replace(/\(.*\)/g, '')
              }
        );

        const visiteurStructure: StructureType = mergeIntoDefault(
          nullStructure,
          CompetitionEquipeVisiteuse.Structure
            ? {
                ...CompetitionEquipeVisiteuse.Structure,
                isRegroupement: false,
                nom: CompetitionEquipeVisiteuse.nom.replace(/\(.*\)/g, '')
              }
            : {
                ...CompetitionEquipeVisiteuse.Regroupement,
                isRegroupement: true,
                nom: CompetitionEquipeVisiteuse.nom.replace(/\(.*\)/g, '')
              }
        );
        const remappedNextMatch = {
          id,
          competition: {
            nom: Competition.nom,
            nomFamille: Competition.Famille.nom,
            classeAgeCode: Competition.ClasseAge.code,
            sexeCode: Competition.ClasseAge.Sexe.code
          },
          localStructure,
          visiteurStructure,
          etat: Etat.nom,
          journee: Journee.nom,
          dateOfficielle,
          dateEffective,
          lien: prochaineRencontre.lien
        };

        return mergeIntoDefault(nullNextMatch, remappedNextMatch);
      }
    });
  }

  if (prochaineRencontre) {
    const { id, Competition, CompetitionEquipeLocale, CompetitionEquipeVisiteuse, Etat, Journee, dateOfficielle, dateEffective } =
      prochaineRencontre || {};
    if (CompetitionEquipeVisiteuse && CompetitionEquipeLocale) {
      const localStructure: StructureType = mergeIntoDefault(
        nullStructure,
        CompetitionEquipeLocale.Structure
          ? {
              ...CompetitionEquipeLocale.Structure,
              isRegroupement: false,
              nom: CompetitionEquipeLocale.nom.replace(/\(.*\)/g, '')
            }
          : {
              ...CompetitionEquipeLocale.Regroupement,
              isRegroupement: true,
              nom: CompetitionEquipeLocale.nom.replace(/\(.*\)/g, '')
            }
      );

      const visiteurStructure: StructureType = mergeIntoDefault(
        nullStructure,
        CompetitionEquipeVisiteuse.Structure
          ? {
              ...CompetitionEquipeVisiteuse.Structure,
              isRegroupement: false,
              nom: CompetitionEquipeVisiteuse.nom.replace(/\(.*\)/g, '')
            }
          : {
              ...CompetitionEquipeVisiteuse.Regroupement,
              isRegroupement: true,
              nom: CompetitionEquipeVisiteuse.nom.replace(/\(.*\)/g, '')
            }
      );
      const remappedNextMatch = {
        id,
        competition: {
          nom: Competition.nom,
          nomFamille: Competition.Famille.nom,
          classeAgeCode: Competition.ClasseAge.code,
          sexeCode: Competition.ClasseAge.Sexe.code
        },
        localStructure,
        visiteurStructure,
        etat: Etat.nom,
        journee: Journee.nom,
        dateOfficielle,
        dateEffective
      };

      nullSettings.prochaine_rencontre = mergeIntoDefault(nullNextMatch, remappedNextMatch);
    }
  }

  const liste_cardsMerged = liste_cards.map(liste_card => {
    return mergeIntoDefault(nullSettings.liste_cards[0], liste_card);
  });

  const liste_blocs_unMerged = liste_blocs_un.map(bloc => {
    return mergeIntoDefault(nullCardMenuElement, bloc);
  });

  const liste_blocs_deuxMerged = liste_blocs_deux.map(bloc => mergeIntoDefault(nullCardMenuElement, bloc));

  return mergeIntoDefault(nullSettings, {
    adresse,
    telephone,
    ligue,
    title,
    description,
    meta_title_home,
    meta_description_home,
    url,
    logo,
    saison_en_cours,
    liste_cards: liste_cardsMerged,
    titre_liste_cards,
    contacts,
    favicons,
    competitionsEquipes,
    liens_rs: {
      fb_lien: liens_rs.fb_lien || '',
      instagram_lien: liens_rs.instagram_lien || '',
      twitter_lien: liens_rs.twitter_lien || '',
      youtube_lien: liens_rs.youtube_lien || '',
      flickr_lien: liens_rs.flickr_lien || '',
      linkedin_lien: liens_rs.linkedin_lien || '',
      twitch_lien: liens_rs.twitch_lien || '',
      snapchat_lien: liens_rs.snapchat_lien || '',
      tik_tok_lien: liens_rs.tik_tok_lien || ''
    },
    newsletter,
    cookies,
    top_news,
    actualite_une,
    partenaires,
    partenaires_page,
    partenaires_une_titre,
    partenaires_une_description,
    partenaires_une_cta,
    banniere,
    rejoignez_nous_background,
    club_id,
    structure_parente,
    rejoignez_nous,
    actualites_une_titre,
    actualites_une_description,
    equipes_une,
    equipes_une_titre,
    equipes_une_description,
    equipes_une_cta,
    equipes_une_lien,
    prochaine_rencontre: nullSettings.prochaine_rencontre,
    prochaine_rencontre_lien,
    prochainesRencontres,
    structure,
    mode_travaux,
    user_id: user_id ? user_id : 0,
    home_bloc_boutique,
    liste_blocs_un: liste_blocs_unMerged,
    liste_blocs_deux: liste_blocs_deuxMerged,
    titre_liste_blocs_un,
    titre_liste_blocs_deux,
    placeholder,
    actualite_ffr_vers_ecosysteme,
    cgus,
    cgu,
    google_verification,
    ville,
    acronyme,
    message_alerte
  });
}
